<template>
  <div class="monitoring-page-wrapper">
      <div>
          <services-tabs />
      </div>
    <v-data-table
      class="mb-6"
      :items="services"
      sort-by="name"
      :loading="isLoading"
      loading-text="Loading... Please wait"
      :headers="headers"
    >
      <template v-slot:top>
        <p class="ma-0 pa-4 text-h6">Microservices</p>
      </template>
      <template v-slot:item.status="{ item }">
        <v-progress-circular
          v-if="item.status === null"
          :size="20"
          indeterminate
          color="#ccc"
        />
        <span v-else>
          {{ item.status === true ? '🟢' : '❌'}}
        </span>
      </template>
    </v-data-table>

    <v-data-table
      class="mb-6"
      :items="concertInfo"
      sort-by="id"
      :loading="concertInfoLoading"
      loading-text="Loading... Please wait"
      no-data-text="No available concerts"
      :headers="concertHeaders"
      hide-default-footer
    >
      <template v-slot:top>
        <p class="ma-0 pa-4 text-h6">Concert info</p>
      </template>
      <template v-slot:item.time="{ item }">
        <span v-if="item.status">Finish in: </span>
        <span v-else>Start in: </span>
        <span ref="timer" id="timer">{{ countdown(item.timer) }}</span>
      </template>
      <template v-slot:item.status="{ item }">

        <span :style="`${item.status ? 'color: limegreen;' : 'color: red;'}`">
          Concert is{{ item.status ? ' ' : ' not '}}running
        </span>
      </template>
    </v-data-table>


    <div class="pa-4 start-concert-container">

      <p class="text-h6">Start concert</p>

      <form class="concert-form">
        <span>Duration (min):</span>
        <v-text-field
          maxlength="4"
          v-model.number="concertDuration"
          hide-details
          outlined
          class="mb-4"
          @keyup.enter="startConcerts"
        />
        <date-picker
          v-if="!startConcertNow"
          v-model="delayedConcertDate"
          mode="dateTime"
          color="gray"
          class="mb-4"
          style="background-color: #2f2f2f;"
          is-dark
          is24hr
       />
        <v-checkbox
          v-model="startConcertNow"
          class="my-0"
        >
          <template v-slot:label class="checkbox-label-slot">
            <span class="checkbox-label">Start concert now</span>
          </template>
        </v-checkbox>
        <v-btn
          large
          outlined
          width="100%"
          class="mb-4"
          :disabled="concertInfo.length > 0 || !concertDuration"
          :loading="startConcertLoading"
          @click="startConcerts"
        >
          Start Concert
        </v-btn>
        <v-btn
          large
          outlined
          width="100%"
          class="mb-4"
          :disabled="concertInfo.length === 0"
          :loading="startConcertLoading"
          @click="stopConcert"
        >
          Stop Concert
        </v-btn>

      </form>

      <form class="concert-form">
        <p class="text-h6">Start bots</p>
        <p class="mb-1">Bots status:</p>
        <p :class="botsRunning ? 'bots-online' : 'bots-offline'">{{ botsRunning ? 'Running ✅' : 'Stopped ❌' }}</p>

        <span>Pods:</span>

        <v-select
          v-model="podsForAvatars"
          :items="pods"
          deletable-chips
          outlined
          chips
          label="Pods"
          multiple
        ></v-select>


<!--        <v-text-field-->
<!--          v-model="podsForAvatars"-->
<!--          placeholder="1,2,3"-->
<!--          hide-details-->
<!--          outlined-->
<!--          class="mb-4"-->
<!--        />-->

        <span>Bots amount:</span>
        <v-text-field
          v-model="botsCount"
          placeholder="5"
          hide-details
          outlined
          class="mb-4"
        />

        <v-btn
          large
          outlined
          width="100%"
          class="mb-4"
          :loading="botsLoading"
          :disabled="!podsForAvatars || !botsCount || botsRunning"
          @click="startBots"
        >
          start bots
        </v-btn>

        <v-btn
          large
          outlined
          width="100%"
          class="mb-4"
          :loading="botsLoading"
          :disabled="!botsRunning"
          @click="stopBots"
        >
          stop bots
        </v-btn>
      </form>
    </div>
  </div>
</template>

<script>
  import loader from "@/config.loader";
  import request from "@/plugins/request";
  import { Constants } from '@/config/constants';
  import DatePicker from 'v-calendar/lib/components/date-picker.umd';
  import ServicesTabs from './ServicesTabs';
  import awsAuth from '@/cognito/aws-auth';


  export default {
    components: {
      DatePicker,
      ServicesTabs
    },
    data() {
      return {
        delayedConcertDate: new Date(),
        concertInfoLoading: true,
        services: [
          {
            name: 'Profiles',
            url: loader.getConfigValue("MICROCERVICES_PROFILES"),
            status: null,
          },
          {
            name: 'Show',
            url: loader.getConfigValue("MICROCERVICES_SHOW"),
            status: null,
          },
          {
            name: 'Tickets',
            url: loader.getConfigValue("MICROCERVICES_TICKETS"),
            status: null,
          },
          {
            name: 'Concert',
            url: loader.getConfigValue("MICROCERVICES_CONCERT"),
            status: null,
          },
          // {
          //   name: 'NATS',
          //   url: loader.getConfigValue("MICROCERVICES_NATS"),
          //   status: null,
          // },
          {
            name: 'Coordinator',
            url: loader.getConfigValue("MICROCERVICES_COORDINATOR"),
            status: null,
          },
          // {
          //   name: 'Logs',
          //   url: loader.getConfigValue("MICROCERVICES_LOGS"),
          //   status: null,
          // },
        ],
        headers: [
          {
            text: "Service",
            sortable: false,
            align: "left",
            value: "name",
          },
          {
            text: "Url",
            sortable: false,
            align: "left",
            value: "url",
          },
          {
            text: "Status",
            sortable: false,
            align: "center",
            value: "status",
          },
        ],
        concertHeaders: [
          {
            text: "Id",
            sortable: false,
            align: "left",
            value: "id",
            width: "50%"
          },
          {
            text: "Status",
            sortable: false,
            align: "center",
            value: "status",
            width: "20%"
          },
          {
            text: "Time",
            sortable: false,
            align: "right",
            value: "time",
            width: "30%"
          },
        ],
        concertInfo: [],
        isLoading: false,
        startConcertNow: true,
        concertDuration: 0,
        startConcertLoading: false,
        countDownInterval: null,
        serviceStatusInterval: null,
        concertStatusInterval: null,
        podsForAvatars: null,
        botsCount: null,
        botsRunning: false,
        botsRunningInterval: null,
        botsLoading: false,

        pods: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
      }
    },
    mounted() {
      var self = this;
      self.getServiceStatus();
      self.checkConcertStatus();
      self.checkBots();

      self.serviceStatusInterval = setInterval(function() {
        self.getServiceStatus();
      }, 5000);

      self.concertStatusInterval = setInterval(function() {
        self.checkConcertStatus();
      }, 60000);

      self.botsRunningInterval = setInterval(function () {
        self.checkBots();
      }, 10000);
    },
    beforeDestroy() {
      let intervals = [this.countDownInterval, this.serviceStatusInterval, this.concertStatusInterval, this.botsRunningInterval];
      intervals.forEach(interval => {
        if (interval) {
          clearInterval(interval);
        }
      })
    },
    methods: {
      checkBots() {
        const self = this;
        awsAuth.checkBots()
          .then(res => {
            if (res.data.id) {
              self.botsRunning = true;
            }
          }).catch(() => {
        });
      },

      stopBots() {
        this.botsLoading = true;
        awsAuth.stopBots().then(() => {
          console.log('Bot stopped');
          this.botsRunning = false;
          this.botsLoading = false;
        })
        .catch(err => {
          this.botsLoading = false;
          console.log('stop bot error - ' + JSON.stringify(err));
        });
      },

      startBots() {
        this.botsLoading = true;
        awsAuth.startBots(this.podsForAvatars.join(','), this.botsCount).then(() => {
          console.log('Bot started');
          this.botsRunning = true;
          this.botsLoading = false;
        })
          .catch(err => {
            this.botsLoading = false;
            console.log('start bot error - ' + JSON.stringify(err));
          });
      },

      parseDiff(diff) {
        if (diff < 0) {
          this.checkConcertStatus();
          return;
        }
        const seconds = Math.floor((diff / 1000) % 60);
        const minutes = Math.floor((diff / 1000 / 60) % 60);
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);

        var returnStr = `${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`;

        // this is 'koostyl' and it will work as long as we only have one concert.
        var timerEl = document.getElementById('timer');
        if (timerEl) {
          timerEl.innerText = returnStr;
        }
      },

      countdown(timer) {
        const self = this;
        self.parseDiff(timer - Date.now());
        self.countDownInterval = setInterval(function() {
          self.parseDiff(timer - Date.now());
        }, 1000);
      },

      async checkConcertStatus() {
        const self = this;
        clearInterval(self.countDownInterval);

        self.concertInfo = [];
        self.concertInfoLoading = true;

        const concerts = await request({ method: 'GET', url: '/concert/api/v1/Manager/GetConcertList' });

        if (concerts.status === 200 && concerts.data.length > 0) {
          for (const id of concerts.data) {
            request({ method: 'GET', url: '/concert/api/Nats/GetSnapshotByConcertId?concertId=' + id })
              .then(res => {

                const startTime = new Date(res.data.start);
                const finishTime = new Date(res.data.finish);
                const currentTime = new Date();
                var running = false;
                var timer;

                const differenceTime = startTime.getTime() - currentTime.getTime();

                if (differenceTime > 900000) {
                  running = false;
                  timer = startTime.getTime() + 900000;
                }
                else {
                  running = true;
                  timer = finishTime.getTime();
                }

                self.concertInfoLoading = false;
                self.concertInfo.push({
                  id: id,
                  status: running,
                  timer: timer,
                })
              })
          }
        } else {
          self.concertInfoLoading = false;
        }
      },

      getServiceStatus() {
        this.services.map(service => {
          service.status = null;
          if (service.url) {
            fetch(service.url, { method: 'GET' })
              .then(resp => {
                if (resp.status === 200) {
                  service.status = true;
                }
              })
              .catch(() => {
                service.status = false;
              });
          } else {
            service.url = '---';
            service.status = false;
          }
        });
      },

      startConcerts() {
        this.startConcertNow ? this.startConcert() : this.startCustomConcert();
      },

      async startConcert() {
        const self = this;
        self.startConcertLoading = true;
        let data = new FormData();
        data.append('duration', self.concertDuration);
        data.append('concertId', Constants.DefaultConcertId);

        const concert = await request({ method: 'POST', url: '/concert/api/Nats/StartDefinedConcertNow', body: data });
        self.startConcertLoading = false;

        if (concert.status === 200) {
          self.checkConcertStatus();
        }

      },
      async startCustomConcert() {
        this.startConcertLoading = true;

        let start = this.delayedConcertDate.toUTCString();
        let finish = new Date(start);
        finish.setMinutes(finish.getMinutes() + this.concertDuration);

        let data = {
          concertId: Constants.DefaultConcertId,
          start: new Date(start).toISOString(),
          finish: new Date(finish).toISOString(),
          pods: Constants.CustomConcertPodsList
        }

        const concert = await request({ method: 'POST', url: '/concert/api/Nats/StartCustomConcert', body: JSON.stringify(data) });
        this.startConcertLoading = false;

        if (concert.status === 200) {
          this.checkConcertStatus();
        }

      },
      async stopConcert() {
        this.startConcertLoading = true;

        let data = new FormData();
        data.append('concertId', Constants.DefaultConcertId);

        const concert = await request({ method: 'POST', url: '/concert/api/Nats/FinishConcert', body: data });
        this.startConcertLoading = false;

        if (concert.status === 200) {
          this.checkConcertStatus();
        }

      },
    },
  }
</script>

<style  scoped>
.v-input--selection-controls  >>> .v-label {
  display: contents !important;
}
.concert-form {
  width: 250px;
}
.start-concert-container {
  background-color: #1E1E1E;
  border-radius: 4px;
}
.monitoring-page-wrapper {
  padding: 100px 70px 0;
  align-items: center;
}
.bots-online {
  color: green;
  font-size: 20px;
}

.bots-offline {
  color: red;
  font-size: 20px;
}

</style>
